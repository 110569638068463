@font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.eot?4h9agq');
    src: url('/fonts/icomoon.eot?4h9agq#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?4h9agq') format('truetype'),
        url('/fonts/icomoon.woff?4h9agq') format('woff'),
        url('/fonts/icomoon.svg?4h9agq#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-control:before {
    content: '\e900';
}
.icon-report:before {
    content: '\e901';
}
.icon-create:before {
    content: '\e902';
}
.icon-view-meter:before {
    content: '\e903';
}
.icon-chevron-up:before {
    content: '\e904';
}
.icon-refresh-icon:before {
    content: '\e905';
}
.icon-download-solid:before {
    content: '\e9bb';
}
.icon-zoom-in:before {
    content: '\e9b5';
}
.icon-zoom-out:before {
    content: '\e9b6';
}
.icon-floor-plan:before {
    content: '\e9b4';
}
.icon-redo:before {
    content: '\e906';
}
.icon-stop:before {
    content: '\e907';
}
.icon-remove:before {
    content: '\e908';
}
.icon-more-horizontal:before {
    content: '\e909';
}
.icon-message-plus-square:before {
    content: '\e90a';
}
.icon-chatbot:before {
    content: '\e90b';
}
.icon-sites:before {
    content: '\e90c';
}
.icon-chat:before {
    content: '\e90d';
}
.icon-person:before {
    content: '\e90e';
}
.icon-view:before {
    content: '\e90f';
}
.icon-stats:before {
    content: '\e910';
}
.icon-external-link:before {
    content: '\e911';
}
.icon-signal:before {
    content: '\e92f';
}
.icon-clock:before {
    content: '\e912';
}
.icon-bar-chart:before {
    content: '\e913';
}
.icon-cancel-fill:before {
    content: '\e914';
}
.icon-no-notifications:before {
    content: '\e915';
}
.icon-menu-pinned:before {
    content: '\e916';
}
.icon-nav-menu:before {
    content: '\e917';
}
.icon-filter:before {
    content: '\e918';
}
.icon-switch-vertical:before {
    content: '\e922';
}
.icon-icon-lock:before {
    content: '\e926';
}
.icon-info-circle:before {
    content: '\e919';
}
.icon-cross:before {
    content: '\e91a';
}
.icon-arrow-right:before {
    content: '\e91b';
}
.icon-document:before {
    content: '\e91c';
}
.icon-camera:before {
    content: '\e91d';
}
.icon-add-thin:before {
    content: '\e9bc';
}
.icon-check:before {
    content: '\e91e';
}
.icon-info:before {
    content: '\e91f';
}
.icon-alert:before {
    content: '\e920';
}
.icon-trash:before {
    content: '\e921';
}
.icon-account:before {
    content: '\e923';
}
.icon-licenses:before {
    content: '\e924';
}
.icon-log:before {
    content: '\e925';
}
.icon-lightbulb-filled:before {
    content: '\e927';
}
.icon-backlog:before {
    content: '\e928';
}
.icon-search:before {
    content: '\e929';
}
.icon-equipment:before {
    content: '\e92a';
}
.icon-building:before {
    content: '\e92b';
}
.icon-avatar:before {
    content: '\e92c';
}
.icon-next:before {
    content: '\e92d';
}
.icon-link:before {
    content: '\e92e';
}
.icon-badge:before {
    content: '\e930';
}
.icon-download:before {
    content: '\e931';
}
.icon-blank-star:before {
    content: '\e932';
}
.icon-calendar:before {
    content: '\e933';
}
.icon-star:before {
    content: '\e934';
}
.icon-hierarchy:before {
    content: '\e935';
}
.icon-chart:before {
    content: '\e936';
}
.icon-layout:before {
    content: '\e937';
}
.icon-meters:before {
    content: '\e938';
}
.icon-globe:before {
    content: '\e939';
}
.icon-down-arrow:before {
    content: '\e93a';
}
.icon-check-fill:before {
    content: '\e93b';
}
.icon-prev:before {
    content: '\e93c';
}
.icon-menu:before {
    content: '\e93d';
}
.icon-help:before {
    content: '\e93e';
}
.icon-cog:before {
    content: '\e93f';
}
.icon-signout:before {
    content: '\e940';
}
.icon-notification:before {
    content: '\e941';
}
