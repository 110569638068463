/* stylelint-disable */
.sidebar {
    @apply fixed top-0 flex h-full w-[240px] flex-col  overflow-x-hidden border-r border-interface-300 bg-primary-900 pt-[52px];
}
.sidebar.sidebarSmall {
    @apply !w-[56px] mobile:hidden;
}
.sidebar.sidebarSmall:hover {
    @apply !w-[240px] md:!w-[56px];
}
.sidebar.sidebarSmall:hover ~ .rightsideArea {
    @apply !w-[calc(100%-240px)] md:!w-[calc(100%-56px)];
}
.animateToggle {
    transition: width 0.3s ease-in-out;
}
.sidebarMenuList {
    @apply w-full p-2 pt-2;
}
.sidebarSmall .menuItem a span {
    @apply justify-center p-[6px] text-[0];
}
.sidebarSmall:hover .menuItem a span {
    @apply md:!justify-center md:!p-[6px] md:!text-[0];
}
/* .sidebar.sidebarSmall:not(:hover) .dropdown {
    @apply hidden;
}
.sidebar.sidebarSmall:hover .dropdown {
    @apply md:hidden;
} */
.sidebarSmall .scrollable {
    @apply overflow-x-hidden;
}
.menuItem a span,
.sidebar.sidebarSmall:hover .menuItem a span {
    @apply flex items-center p-2 pl-9 text-sm text-common-white;
}
.sidebar.sidebarSmall:hover .menuItem a span {
    @apply justify-start;
}
.menuItem a i,
.sidebar.sidebarSmall:hover a i {
    @apply absolute left-[7px] mr-2 text-base text-primary-600;
}
.sidebarSmall .menuItem a i {
    @apply static mr-0;
}
.sidebarSmall:hover .menuItem a i {
    @apply md:!static md:!mr-0;
}
.menuItem li {
    @apply relative;
}
.menuItem li a {
    @apply block min-h-[44px];
}
.menuItem li .dropdown {
    @apply pointer-events-none absolute right-4 top-[15px] cursor-pointer text-[6px]  text-primary-600;
}
.sidebarSmall .menuItem li .dropdown {
    @apply hidden;
}
.sidebarSmall:hover .menuItem li .dropdown {
    @apply block md:hidden;
}
.menuItem li a span {
    @apply rounded font-medium hover:bg-primary-800;
}
.menuItem li.active a span,
.sidebar.sidebarSmall:hover .menuItem li.active a span {
    @apply bg-primary-600 text-common-white;
}
.menuItem li.active .dropdown,
.sidebar.sidebarSmall:hover .menuItem li.active .dropdown {
    @apply text-common-white;
}
.menuItem li.active a span i,
.sidebar.sidebarSmall:hover .menuItem li.active a span i {
    @apply text-common-white;
}
.menuItem li .subMenu {
    @apply mt-1 overflow-hidden transition-all duration-500 ease-in-out;
}
.menuItem li.active .subMenu
/* .menuItem li:hover .subMenu  */ {
    @apply block;
}
.menuItem li .subMenu li a span,
.sidebar.sidebarSmall:hover .menuItem li .subMenu li a span {
    @apply ml-6 bg-transparent pl-[12px] text-common-white hover:bg-primary-800;
}
.menuItem li .subMenu li.active a span,
.sidebar.sidebarSmall:hover .menuItem li .subMenu li.active a span {
    @apply text-primary-500 hover:bg-transparent;
}
/* .menuItem li.active .subMenu li a span.active {
    @apply bg-transparent text-primary-600;
} */
/* .menuItem li.active .subMenu {
    @apply relative;
} */
.menuItem li .subMenu::after,
.sidebar.sidebarSmall:hover .menuItem li .subMenu::after {
    content: '';
    @apply absolute left-[13px] top-[0px] h-[92%] w-0.5 rounded-sm bg-primary-600;
}
.sidebarSmall .menuItem li .subMenu::after {
    @apply left-[16px];
}
.sidebarSmall:hover .menuItem li .subMenu::after {
    @apply md:!left-[16px];
}
.sidebar .sidebarButton {
    @apply relative left-2 flex h-[34px] w-[218px] items-center justify-center rounded-full border-2 border-primary-600 bg-transparent font-semibold text-primary-500 hover:bg-primary-600 hover:text-common-white;
}
.sidebar.sidebarSmall .sidebarButton {
    @apply w-[37px] border-0 pl-0;
}
.sidebar.sidebarSmall .sidebarButton > div {
    @apply hidden;
}
.sidebar.sidebarSmall:hover .sidebarButton > div {
    @apply block md:hidden;
}
.sidebar .sidebarButton i {
    @apply text-[12px] !font-semibold;
}
.sidebar.sidebarSmall .sidebarButton i {
    @apply text-[16px] !font-semibold;
}
.sidebar.sidebarSmall:hover .sidebarButton {
    @apply w-[218px] border-2  border-primary-600 md:w-[37px];
}
.sidebar .ecoPilot,
.sidebar.sidebarSmall:hover .ecoPilot {
    @apply relative ml-2 mr-2 mt-3 flex cursor-pointer items-start gap-x-2 rounded-[4px] bg-primary-800 p-2;
}
.sidebar.sidebarSmall .ecoPilotData {
    @apply hidden;
}
.sidebar.sidebarSmall:hover .ecoPilotData {
    @apply block md:hidden;
}
.sidebar.sidebarSmall .ecoPilot,
.sidebar.sidebarSmall:hover .ecoPilot {
    @apply ml-0 mr-0 bg-transparent;
}
.sidebar .ecoPilotIcon {
    background-image: url('/eco-pilot-icon.svg');
    @apply h-[40px] w-[40px] bg-cover bg-no-repeat;
}
