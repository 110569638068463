.heading {
    @apply mb-2 text-3xl font-bold text-interface-950 ipad:text-xl mobile:text-lg;
}
.tagline {
    @apply mb-8 text-sm font-normal text-interface-600 mobile:text-sm;
}
.bgImage {
    background-image: url('/auth-bg.png');
    @apply overflow-hidden rounded-tl-[32px] bg-cover bg-top bg-no-repeat;
}
.authLogo {
    @apply w-[123px] pb-3;
}
.authFormSection {
    @apply relative w-[760px]  flex-shrink-0 overflow-y-auto px-24 pt-[80px]  4xl:w-[660px] 3xl:w-[600px] 2xl:max-h-[630px] 2xl:w-[550px] 2xl:px-16 2xl:pt-9 md:w-[480px] md:px-8 ipad:w-[400px] mobile:w-full;
}
