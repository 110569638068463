.navigation {
    /* ipad:absolute ipad:border-t ipad:border-interface-200 ipad:left-0 ipad:right-0 ipad:top-[64px] ipad:shadow-header-shadow ipad:bg-common-white */
    @apply relative;
}
.navigation ul {
    @apply flex items-center justify-center;
}
.navigation ul li {
    @apply relative mr-2 last-of-type:mr-0;
}
.navigation ul li a,
.navigation ul li p {
    @apply flex items-center justify-center p-2 text-sm font-normal text-common-white hover:rounded-full;
}
.navigation ul li p {
    @apply cursor-pointer;
}
.navigation ul li a i,
.navigation ul li p i {
    @apply text-[20px];
}
.tabList.tabList li a,
.tabList.tabList li .tabItem {
    text-wrap: nowrap;
    @apply relative px-0 pb-2 text-sm font-medium text-interface-600 hover:bg-transparent;
}
.navigation ul li.active a,
.navigation ul li.active p {
    @apply font-medium text-interface-900;
}
.navigation ul li.active {
    @apply relative;
}
/* .navigation ul li.active:after {
    content: '';
    @apply absolute bottom-0 left-0 h-[2px] w-full bg-primary-500;
} */
.tabList {
    @apply border-b border-t-0 border-interface-300 pl-4 ipad:static ipad:bg-transparent ipad:shadow-none;
}
.tabList.tabList ul {
    @apply flex items-start justify-start;
}
.tabList.tabList li {
    @apply mr-6 last-of-type:mr-0;
}
.tabList.tabList li .tabItem {
    @apply cursor-pointer;
}
.tabList.tabList li.active a,
.tabList.tabList li.active .tabItem {
    @apply text-primary-500;
}
.tabList.tabList li.active:after {
    content: '';
    @apply absolute bottom-0 left-0 h-[2px] w-full bg-primary-500;
}
