.alert {
    @apply flex min-h-[38px] items-center rounded-md border border-success-500 bg-success-100 px-3 transition-opacity duration-100;
}
.alert.sm {
    @apply min-h-[36px] p-2 px-3;
}
.alert.md {
    @apply min-h-[44px] p-2 px-3 text-base;
}
.alert.success {
    @apply border-success-500 bg-success-50 text-success-600;
}
.alert.danger i {
    @apply text-[13px];
}
.alert.danger {
    @apply border-danger-600 bg-danger-50 text-danger-600;
}
.alertBlock {
    @apply mb-0 flex rounded-lg bg-success-100 px-[28px] py-[24px] transition-opacity duration-100;
}
.alertMessage {
    @apply ml-3 text-base font-medium text-common-black;
}
.alertBlock.success {
    @apply border-success-500 bg-success-50 text-success-600;
}
.alertBlock.danger i {
    @apply text-2xl text-error-dark;
}
.alertBlock.danger {
    @apply bg-danger-50 text-danger-600;
}
