.btn {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    @apply relative flex h-10 flex-shrink-0 items-center justify-center overflow-hidden rounded-md border px-4 text-sm font-medium text-common-white outline-0 transition-all duration-75 focus:ring-0;
}
.btn .labelText {
    @apply font-theme leading-normal;
}
.btn.sm {
    @apply h-[36px] rounded-md px-2 text-sm;
}
.btn .spinner {
    @apply absolute left-0 top-0 flex h-full w-full items-center justify-center opacity-0;
}
.btn.md {
    @apply h-12 text-base ipad:h-10;
}
.btn.md .labelText {
    @apply text-base font-semibold ipad:text-sm;
}
.btn.base {
    @apply h-9 text-sm;
}
.btn.other {
    @apply h-[41px];
}
.btn.xs {
    @apply h-[30px] rounded px-2 py-2 text-xs;
}
.primary.primary {
    @apply border-transparent bg-primary-500 text-common-white transition-all hover:bg-primary-600;
}
.secondary.secondary {
    @apply border-0 bg-interface-500 text-common-white hover:border-interface-700;
}
.success.success {
    @apply bg-success-600 text-common-white hover:bg-success-700;
}
.live.live {
    @apply border-none bg-[#EC001F] uppercase text-common-white;
}
.danger.danger {
    @apply border-none bg-danger-600 uppercase text-common-white;
}
.gray.gray {
    @apply bg-interface-400 text-common-white hover:bg-interface-400;
}
.light-gray.light-gray {
    @apply border-interface-500 bg-interface-500 text-sm text-interface-200 hover:bg-interface-500;
}
.light-gray-outline {
    @apply border border-interface-300 bg-common-white text-interface-600;
}
.outline.outline {
    @apply border-primary-500 bg-common-white text-primary-500;
}
.outline-primary.outline-primary {
    @apply border-primary-500 bg-common-white text-primary-500 shadow-md;
}
.outline-interface.outline-interface {
    @apply border-interface-200 bg-transparent text-interface-600 shadow-sm ipad:bg-common-white;
}
.outline-secondary.outline-secondary {
    @apply border-interface-700 bg-interface-800 text-common-white;
}
.dropdown-link.dropdown-link {
    @apply h-11 flex-col items-start justify-center rounded-none border-none px-[17px] py-0 hover:bg-primary-100 hover:bg-opacity-40 hover:text-common-white;
}
.dropdown-menu i {
    @apply text-primary-500;
}
.dropdown-link.dropdown-link i {
    @apply text-[18px] text-interface-500;
}
.dropdown-link.dropdown-link span {
    @apply text-sm leading-3 text-interface-900;
}
.dropdown-menu {
    @apply h-[34px] rounded-md border-none text-left justify-start bg-common-white px-2 py-[6px] font-normal text-common-black hover:bg-primary-50;
}
.dark.dark {
    @apply border-interface-600 bg-interface-600 text-interface-200;
}
.white.white {
    @apply border border-interface-300 bg-common-white font-medium text-interface-700;
}
.white.white .labelText {
    @apply xl:text-[12px];
}
.link.link {
    @apply h-auto border-0 py-0 text-primary-500;
}
.link.link .labelText {
    @apply text-base font-bold;
}
.btn.primary-link.primary-link {
    @apply h-auto rounded-md bg-primary-500 px-5 transition-all hover:bg-primary-600;
}
.btn.loading .labelText,
.btn.disabled .labelText {
    @apply opacity-0;
}
.btn.primary-link.primary-link .labelText {
    @apply text-common-white;
}
.header-link.header-link {
    @apply border-0 text-interface-600;
}
.google.google {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    @apply border border-interface-300 bg-common-white text-interface-900;
}
.google-secondary.google-secondary {
    @apply border border-interface-300 bg-common-white text-interface-900;
}
.normalizeLink.normalizeLink {
    @apply h-auto border-0 px-0 py-0 text-interface-600 shadow-none;
}
.normalizeLink.normalizeLink span {
    @apply text-sm;
}
.btn.link-xs span {
    @apply py-[3px] text-sm font-normal text-common-white;
}
.btn.link-sm span {
    @apply py-1 text-sm text-common-white;
}
.btn .text-justify-start {
    @apply justify-start;
}
.btn .iconPosition.right {
    @apply flex-row-reverse;
}
.btn .iconPosition.right i {
    @apply ml-2 mr-0;
}
.btn.loading,
.btn[loading],
.btn.disabled,
.btn[disabled] {
    @apply pointer-events-none border-transparent text-transparent;
}
.btn.loading .spinner,
.btn.disabled .spinner,
.btn[disabled] .spinner {
    @apply pointer-events-none opacity-100;
}
.btn.disabled,
.btn[disabled] {
    @apply pointer-events-none opacity-70;
}
/* Loader */
.loader {
    @apply relative mb-2 w-full;
}
.spinnerInner {
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    @apply h-6 w-6 rounded-full border-[3px] border-t-[3px] border-interface-200 border-t-primary-500;
}
.spinnerInner.chatLoading {
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    @apply h-4 w-4 rounded-full border-[2px] border-t-[2px] border-common-white border-t-primary-400;
}
/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loaderMsg {
    @apply ml-2 text-sm text-interface-800;
}
.loaderMsg2 {
    @apply ml-2 text-center text-sm text-interface-500;
}
.ChatLoader {
    box-shadow: 2px 0 #9ca3af, -2px 0 #9ca3af;
    animation: flash 1s ease-out infinite alternate;
    @apply relative h-2 w-2 rounded-full bg-interface-400;
}

@keyframes flash {
    0% {
        background-color: #d1d5db;
        box-shadow: 10px 0 #d1d5db, -10px 0 #9ca3af;
    }
    50% {
        background-color: #9ca3af;
        box-shadow: 10px 0 #d1d5db, -10px 0 #d1d5db;
    }
    100% {
        background-color: #d1d5db;
        box-shadow: 10px 0 #9ca3af, -10px 0 #d1d5db;
    }
}
