/* stylelint-disable */
@tailwind base;
@tailwind components;
/* ================
    Custom
=================== */
html {
    @apply h-full font-normal;
}
body {
    @apply overflow-x-hidden bg-body-bg font-theme text-sm font-normal text-interface-900;
}
h1,
.h1,
h2,
.h2 {
    @apply mb-2 font-bold;
}
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    @apply mb-2 font-semibold ipad:mb-1.5;
}
h1,
.h1 {
    @apply text-3xl ipad:text-2xl mobile:text-xl;
}
.h2,
h2 {
    @apply text-2xl ipad:text-xl mobile:text-base;
}
.h3,
h3 {
    @apply text-xl mobile:text-base;
}
.h4,
h4 {
    @apply text-lg;
}
.h5,
h5 {
    @apply text-base mobile:text-sm;
}
.h6,
h6 {
    @apply text-sm;
}
ul {
    @apply relative;
}
ol li {
    @apply list-inside list-decimal text-sm;
}
a {
    @apply text-primary-600;
}
.container {
    @apply mx-auto w-[calc(100%-88px)] max-w-[1080px] py-[42px] ipad:w-full ipad:px-6 ipad:py-[22px] mobile:w-full mobile:px-5 mobile:py-5;
}
.page-heading {
    @apply mb-2;
}
.separator {
    @apply my-6 h-[1px] w-full bg-interface-200;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
#nprogress .bar {
    @apply bg-primary-500;
}
#nprogress .spinner {
    @apply hidden;
}
.chat-scrollable {
    @apply overflow-y-auto;
}
.scrollable,
.infinite-scroll-component {
    @apply overflow-auto;
}
.scrollable::-webkit-scrollbar-track,
.infinite-scroll-component::-webkit-scrollbar-track,
.style-table-two .base-table div::-webkit-scrollbar-track,
.base-table .sc-fzXfMA::-webkit-scrollbar-track,
select::-webkit-scrollbar-track {
    @apply rounded-[50px] bg-common-white;
}
.scrollable::-webkit-scrollbar,
.infinite-scroll-component::-webkit-scrollbar,
.style-table-two .base-table div::-webkit-scrollbar,
.base-table .sc-fzXfMA::-webkit-scrollbar,
select::-webkit-scrollbar {
    @apply h-[6px] w-[6px] rounded-[50px];
}
.scrollable::-webkit-scrollbar-thumb,
.infinite-scroll-component::-webkit-scrollbar-thumb,
.style-table-two .base-table div::-webkit-scrollbar-thumb,
.base-table .sc-fzXfMA::-webkit-scrollbar-thumb,
select::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-300;
}
/* small sidebar */
.table-placeholder span,
.table-placeholder div {
    @apply !text-interface-300;
}
.separator-with-title > span {
    @apply relative bg-interface-100 px-2 text-xs font-medium text-interface-600;
}
.separator-with-title.secondary span {
    @apply bg-transparent text-primary-600;
}
/* .small-sidebar .sidebar-selectbox > div > div > span {
    @apply mr-0;
} */
/* .small-sidebar .sidebar-selectbox > div > div > span + span,
.small-sidebar .icon-down-arrow {
    @apply hidden;
} */
.small-sidebar .separator-with-title.secondary span {
    @apply relative top-[-1px] bg-primary-500 px-2 py-[10px] text-[0];
}
.custom-date-time i {
    @apply text-base text-interface-400;
}
.btn-tag i {
    @apply relative mr-2 text-base;
}
.dropdown-button i {
    @apply relative left-[6px] top-[2px] bg-interface-400;
}
.tabList button i {
    @apply mr-2 text-base mobile:hidden;
}
.rdt_Table .actions i {
    @apply mr-1 cursor-pointer p-2 text-lg text-interface-600;
}
.rdt_Table .actions.log-table i {
    @apply mr-1 cursor-pointer p-2 text-base text-interface-400;
}
.content-placeholders-animation.pipeline-content-placeholders-animation i {
    @apply hidden;
}
.table-placeholder.table-placeholder i {
    @apply inline-block h-4 w-4 animate-pulse bg-interface-400 text-opacity-0;
}
.small-sidebar .separator-with-title.secondary span i {
    @apply relative top-1 mr-0 text-xs text-common-white;
}
/* .small-sidebar .sidebar-selectbox {
    @apply pointer-events-none;
} */
/* .small-sidebar .title-wrapper {
    @apply top-[-3px] pt-3;
} */
/* .small-sidebar .title-wrapper span.title {
    @apply hidden;
} */
/* .small-sidebar .lock-icon {
    @apply ml-1 text-lg;
} */
.animateMenuItems .menu-item {
    animation: fadeIn 0.5s ease-in-out forwards;
}
/* sidebar lg (covers screen size upto 1199px) */
.nav-lg-screens {
    @apply lg:fixed lg:z-[11];
}
.small-sidebar {
    @apply lg:fixed lg:z-[11];
}
.rightside-lg {
    @apply lg:w-[calc(100%-56px)] !important;
}
@keyframes fadeIn {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}
/* phone field */
.PhoneInputCountry.PhoneInputCountry {
    @apply hidden;
}
.PhoneInputInput.PhoneInputInput {
    @apply bg-transparent py-[8px] placeholder:text-interface-600 focus:border-primary-300 focus:outline-none;
}
.custom-date-time input,
.custom-date-time .react-datepicker-wrapper,
.custom-date-time .react-datepicker__input-container {
    @apply absolute left-0 right-0 top-0 h-full w-full overflow-hidden text-ellipsis rounded-md border-none bg-none px-3 pr-8 outline-none placeholder:text-interface-600;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
/* =============
    Datepicker
=============== */
.custom-date-time .react-datepicker {
    @apply overflow-hidden rounded-lg border-interface-100 bg-common-white shadow focus:border-primary-300 focus:outline-none;
}
.react-datepicker__month-container {
    @apply relative p-1.5 pt-[80px];
}
.custom-date-time .react-datepicker__header {
    @apply pointer-events-none absolute left-0 right-0 top-0 mx-auto h-full w-full border-none bg-transparent pt-3;
}
.custom-date-time .react-datepicker__year--container .react-datepicker__header {
    @apply pointer-events-none relative mx-auto h-full w-full border-none bg-transparent pt-3;
}
.custom-date-time .react-datepicker__year .react-datepicker__year-wrapper {
    @apply max-w-[146px] justify-center;
}
.react-datepicker__navigation {
    @apply top-[6px];
}
.disabled-yearpicker input {
    @apply pointer-events-none border-interface-400 bg-interface-200 text-interface-950 opacity-50;
}
.custom-date-time
    .react-datepicker__year
    .react-datepicker__year-wrapper
    .react-datepicker__year-text {
    @apply w-auto p-2;
}
.custom-date-time
    .react-datepicker__year
    .react-datepicker__year-wrapper
    .react-datepicker__year-text.react-datepicker__year-text--selected {
    @apply bg-primary-500 text-common-black;
}
.custom-date-time
    .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown {
    @apply pointer-events-auto;
}
.custom-date-time
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    @apply pointer-events-auto mt-4 cursor-pointer;
}
.custom-date-time .react-datepicker__year-read-view--down-arrow,
.custom-date-time .react-datepicker__month-read-view--down-arrow,
.custom-date-time .react-datepicker__month-year-read-view--down-arrow,
.custom-date-time .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    @apply border-common-black hover:border-common-black;
}
.react-datepicker-popper {
    @apply !z-[2];
}
.react-datepicker-popper[data-placement^='top'] {
    @apply !pb-1;
}
.models-datepicker .react-datepicker-popper {
    @apply !z-50;
}
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    @apply border-b-common-white;
}
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    @apply border-b-interface-100;
}
.react-datepicker__current-month {
    transform: translate(-50%, -20%);
    @apply absolute left-[50%];
}
.react-datepicker__day-names {
    @apply relative after:absolute after:bottom-1 after:left-0 after:right-0 after:mx-auto after:h-[1px] after:w-[calc(100%-10px)] after:bg-interface-100 after:content-[''];
}
.custom-date-time .react-datepicker__day--keyboard-selected,
.custom-date-time .react-datepicker__month-text--keyboard-selected,
.custom-date-time .react-datepicker__quarter-text--keyboard-selected,
.custom-date-time .react-datepicker__year-text--keyboard-selected,
.react-datepicker__day.react-datepicker__day--selected {
    @apply bg-primary-500 text-common-white hover:bg-primary-500;
}
.custom-date-time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    @apply bg-primary-500 hover:bg-primary-500;
}
.custom-date-time .react-datepicker__day-name,
.custom-date-time .react-datepicker__day,
.custom-date-time .react-datepicker__time-name {
    @apply h-9 w-9 py-[5px];
}
.custom-date-time .icon-section {
    @apply absolute right-0 top-0 flex h-full items-center justify-center px-3.5;
}
.custom-date-time .react-datepicker__month-read-view {
    @apply absolute left-0;
}
/* custom-date-picker year dropdown */
.custom-date-time .react-datepicker__year-select {
    @apply scrollable cursor-pointer rounded-[4px] border border-interface-300 px-2 py-[2px] shadow-sm hover:bg-interface-100 focus:outline-none;
}
.custom-date-time .react-datepicker__year-select option {
    @apply p-1 hover:bg-interface-100 !important;
}
/*.custom-date-time .react-datepicker__year-read-view {
    transform: translate(50%, -20%);
    @apply pointer-events-auto absolute left-0 top-[5px] h-9 w-1/2  bg-common-black opacity-0;
}
 .custom-date-time
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll,
.custom-date-time
    .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--scroll {
    @apply absolute top-0 left-0 right-0 w-full h-full mx-auto;
}
.custom-date-time .react-datepicker__year-dropdown {
    @apply pointer-events-auto absolute  left-0  top-0 flex h-full  w-full flex-row-reverse flex-wrap-reverse gap-3 border-none  bg-common-white px-[44px] py-[54px];
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option {
    @apply left-0 flex w-[25%] items-center justify-center border-none bg-common-white p-4 text-center;
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:first-of-type,
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:last-of-type {
    background-position: center;
    background-size: 28px auto;
    @apply absolute left-0 right-0 top-2 h-[40px] w-[94%] bg-interface-100 bg-no-repeat;
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:first-of-type {
    @apply text-interface-900;
}
.custom-date-time .react-datepicker__navigation--years-upcoming {
    background-position: 2px 6px;
    background-size: 28px;
    @apply bg-[url('/storage/arrow-top.svg')];
}
.custom-date-time
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:last-of-type {
    @apply bottom-2 top-auto bg-[url('/storage/arrow-down.svg')];
}
.custom-date-time
    .react-datepicker__year-option.react-datepicker__year-option--selected_year {
    @apply rounded-md bg-primary-500 text-common-white;
}
.custom-date-time .react-datepicker__year-option--selected {
    @apply hidden;
} */
.react-datepicker__day.react-datepicker__day--outside-month {
    @apply text-interface-300;
}
.react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--outside-month {
    @apply text-common-white;
}
/* .filter & .filter-form styles */
.range-picker {
    @apply pl-0;
}
.range-picker-fields {
    @apply grid grid-cols-12 gap-3 mobile:flex mobile:w-full mobile:flex-col;
}
.filter {
    @apply grid grid-cols-12 justify-items-end gap-3 md:flex md:flex-col;
}
.filter-form {
    @apply grid grid-cols-9 gap-0 mobile:flex mobile:flex-col mobile:gap-y-4;
}
.filter-container {
    @apply h-[38px] rounded-md shadow-sm mobile:h-auto mobile:w-full mobile:shadow-none;
}
.filter-form .date-picker-form-control {
    @apply rounded-br-none rounded-tr-none border-r-0 mobile:!rounded-br-md mobile:!rounded-tr-md mobile:!border-r;
}
.schedule-range-picker
    .filter-form
    .only-date-picker
    .date-picker-form-control {
    @apply rounded-md border-r;
}
.filter-end-date .date-picker-form-control {
    @apply rounded-bl-none rounded-tl-none border-r-0 mobile:!rounded-bl-md mobile:!rounded-tl-md mobile:!border-r;
}
.schedule-range-picker .filter-end-date .date-picker-form-control {
    @apply rounded-r-md rounded-bl-none rounded-tl-none border-r mobile:!rounded-bl-md mobile:!rounded-tl-md mobile:!border-r;
}
.custom-selectbox select {
    @apply min-w-[100px] overflow-hidden text-ellipsis pr-8;
}
.filter-form .custom-selectbox select {
    @apply rounded-bl-none rounded-tl-none mobile:!rounded-bl-md mobile:!rounded-tl-md;
}
.schedule-range-picker .filter-form .custom-selectbox select {
    @apply rounded-md;
}
.signals-filter.custom-selectbox {
    @apply w-[250px] lg:w-full;
}
.mobile-filter {
    transition: height 0.3s ease, overflow 0s 0.3s;
    @apply md:mt-4 md:h-0 md:overflow-hidden;
}
.mobile-filter.active {
    transition: height 0.3s ease, overflow 0s;
    @apply overflow-visible lg:mt-2 md:h-[auto];
}
/* textbox */
.filter-form .border-right-none {
    @apply !rounded-br-none !rounded-tr-none !border-r-0 mobile:!rounded-br mobile:!rounded-tr mobile:!border;
}
.filter-form .border-rounded-none {
    @apply !rounded-none !border-r-0 mobile:!rounded-md mobile:!border-r;
}
.filter-form .custom-input:focus {
    @apply !border-interface-300;
}
.filter-form .dteCalendarIcon {
    @apply !top-[10px] text-interface-400;
}
/* =============
    Table
=============== */
.rdt_Table.rdt_Table {
    @apply w-full border-b border-interface-300 text-xs;
}
.rdt_Table .rdt_TableCell {
    @apply break-normal text-common-black mobile:p-3;
}
.rdt_Table .rdt_TableHeadRow {
    @apply min-h-[35px];
}
.rdt_TableCol.rdt_TableCol {
    @apply border-b border-interface-300 bg-interface-100 text-xs normal-case text-interface-600;
}
.rdt_Table .rdt_TableRow {
    @apply bg-common-white even:bg-interface-50;
}
.table-placeholder .rdt_TableRow {
    @apply flex h-[45px] w-full animate-pulse items-center justify-center;
}
.base-table .rdt_Table .rdt_TableRow,
.MjKnz:not(:last-of-type),
.base-table .rdt_Table:not(:last-of-type) {
    @apply border-none border-b-transparent;
}
.base-table a {
    @apply text-sm text-common-black;
}
/* =============
    Table Placeholder
=============== */
.table-placeholder {
    @apply font-redacted;
}
.table-placeholder .rdt_Table .rdt_TableBody .rdt_TableRow.rdt_TableRow {
    @apply font-redacted text-interface-400;
}
.table-placeholder .rdt_TableHead {
    @apply flex h-full w-full flex-col items-center;
}
.table-placeholder .rdt_TableCol {
    @apply w-auto;
}
.table-placeholder .rdt_TableBody {
    @apply flex flex-col;
}
.table-placeholder .rdt_TableCell {
    @apply w-auto;
}
.table-placeholder
    .rdt_Table
    .rdt_TableHead
    .rdt_TableHeadRow
    .rdt_TableCol.rdt_TableCol,
.table-placeholder a,
.table-placeholder .status-loader {
    @apply font-redacted text-interface-400;
}
.table-placeholder
    .rdt_Table
    .rdt_TableHead
    .rdt_TableHeadRow
    .rdt_TableCol.rdt_TableCol {
    @apply py-2;
}
/* sort icon */
.table-placeholder .iPCvQj,
.table-placeholder .iGolrC,
.table-placeholder .dMqwuq {
    @apply hidden;
}
.table-placeholder .status-loader.status-loader {
    @apply bg-interface-300 text-opacity-0;
}
.table-placeholder .rdt_Table .rdt_TableCell {
    @apply animate-pulse font-redacted text-interface-400;
}
.table-placeholder .rdt_TableHeadRow {
    @apply flex w-full items-center bg-body-bg;
}
.table-placeholder.table-placeholder.table-placeholder img,
.table-placeholder.table-placeholder.table-placeholder .text-placeholder {
    @apply hidden !important;
}
.text-placeholders,
.content-placeholders-animation,
.content-placeholders-animation .status-loader.status-loader,
.text-placeholders .status-loader.status-loader,
.table-placeholder.table-placeholder .btn div span,
.table-placeholder.table-placeholder .user-streamer {
    @apply animate-pulse font-redacted text-interface-700;
}
.separator-with-title {
    @apply relative mb-4;
}
.separator-with-title:before {
    content: '';
    @apply absolute left-0 right-0 top-[11px] h-[1px] w-[100%] bg-interface-300;
}
.small-sidebar .separator-with-title::before {
    content: '';
    @apply w-[140%];
}
.separator-with-title.secondary:before {
    @apply bg-transparent;
}
.small-sidebar .separator-with-title.secondary:before {
    @apply left-[6px] top-[13px] h-[2px] w-[74%] bg-primary-500;
}
.btnMenu {
    @apply fixed right-[26px] top-5 z-10 cursor-pointer mobile:right-[18px];
}
.btnMenu:before {
    content: '\2261';
    @apply text-[50px] text-primary-500;
}
.backdrop {
    @apply fixed z-[9] hidden h-full w-full bg-common-black bg-opacity-40 ipad:block;
}
.dashbaordCard,
.default-card {
    @apply rounded-lg border border-interface-300 bg-common-white p-4;
}
.bookmark-section,
.recents-section {
    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.21),
        0px 1px 1px 0px rgba(9, 30, 66, 0.15);
    @apply relative flex border-b border-interface-300 bg-common-white p-2 even:bg-interface-50 last-of-type:border-0;
}
.bookmark-list,
.recents-list {
    @apply flex;
}
.bookmark-list li {
    @apply relative mr-3 text-xs text-interface-600;
}
.bookmark-list li:after {
    content: '/';
    @apply relative right-[-6px] last-of-type:hidden;
}
.bookmark,
.recent {
    @apply -my-3 -mr-4 py-3 pr-4;
}
.recents-list {
    @apply mobile:flex-col;
}
.recents-list li {
    @apply relative mr-3;
}
.recents-list li:after {
    content: '|';
    @apply absolute right-[-8px] top-0 text-interface-600 last-of-type:hidden mobile:hidden;
}
/* setup organization -> project-slug */
.project-slug .icon-check-fill {
    @apply right-[11px] top-[30px] text-lg text-success-500;
}
.project-slug .icon-alert {
    @apply text-2xl text-danger-500;
}
.project-slug.no-label .icon-alert,
.project-slug.no-label .icon-check-fill {
    @apply top-[5px];
}
/* end of setup organization -> project-slug */
.status-circle {
    @apply relative top-0.5 mr-2 inline-block h-3 w-3 rounded-full bg-interface-200;
}
.status-circle.electricBaseLoad {
    @apply bg-warning-500;
}
.status-circle.electricActiveLoad {
    @apply bg-[#FFC107];
}
.status-circle.gasBaseLoad {
    @apply bg-[#008EC2];
}
.status-circle.gasActiveLoad {
    @apply bg-[#00aeef];
}
.rolling-12-months-table .rdt_TableRow:first-child {
    @apply font-bold;
}
.organization-index {
    @apply flex w-full overflow-hidden rounded-lg bg-interface-50 shadow;
}
.organization-index .treeview-block {
    @apply h-[calc(100vh-210px)]  bg-common-white;
}
.organization-index .left-side {
    @apply w-full max-w-[600px] lg:max-w-full !important;
}
.organization-index .left-side .campuses-list {
    @apply border-r border-interface-300 bg-common-white;
}
.organization-manager .right-side,
.organization-index .left-side .campuses-list {
    @apply scrollable h-[calc(100vh-210px)] overflow-y-auto;
}
.organization-index-header {
    @apply flex h-10 items-center border-b border-interface-300 bg-interface-100 px-6;
}
.organization-index-header h2 {
    @apply mb-0 text-sm font-medium text-interface-600;
}
.treeview-basic.tree {
    @apply m-0 list-none p-0;
}
.treeview-basic .tree-node,
.treeview-basic .tree-node-group {
    list-style: none;
    margin: 0;
    padding: 0;
}
.treeview-basic .tree-node.tree-node__branch {
    @apply flex min-h-[40px] w-full items-center border-b border-interface-200 pl-6 text-sm text-interface-950 outline-none hover:bg-primary-50;
}
.treeview-basic .tree-leaf-list-item {
    @apply block w-full border-b border-interface-200 pl-6 text-sm text-interface-950 outline-none hover:bg-primary-50;
}
.treeview-basic .tree-leaf-list-item--focused,
.treeview-basic .tree-leaf-list-item.tree-leaf-list-item--selected,
.treeview-basic
    .tree-node.tree-node--expanded.tree-node--selected.tree-node__branch,
.tree-branch-wrapper .tree-node.tree-node--expanded {
    @apply bg-primary-50 text-primary-500 outline-none;
}
.tree-node--selected.tree-node--focused {
    @apply !outline-none;
}
/* .treeview-basic
    .tree-node.tree-node__branch.tree-node--expanded:not(.treeview-basic
        .tree-node-group.tree-node-group--expanded
        .tree-node.tree-node__branch.tree-node--expanded) {
    @apply bg-primary-50 text-primary-500;
} */
.treeview-basic .tree-node.tree-node__branch .icon-down-arrow {
    @apply block -rotate-90 text-[6px] text-interface-600;
}
.treeview-basic
    .tree-node.tree-node__branch.tree-node--expanded
    .icon-down-arrow {
    @apply rotate-0;
}
.treeview-basic
    .tree-node.tree-node--expanded.tree-node__branch
    .icon-down-arrow {
    @apply text-primary-500;
}
.treeview-basic .tree-branch-wrapper,
.treeview-basic .tree-node__leaf {
    outline: none;
}
.treeview-basic .tree-node--focused {
    outline-color: transparent;
    outline-style: auto;
    outline-width: 2px;
    display: block;
}
.treeview-basic .tree-node__branch {
    display: block;
}
.treeview-basic .tree-node {
    cursor: pointer;
}
/* Organization -> left side section */
.treeViewWrapper {
    @apply flex min-h-[40px] w-full items-center;
}
.expandIcon {
    @apply flex h-6 w-6 flex-shrink-0 items-center justify-center text-center;
}
/* Organization -> right side section */
/* campus */
.campus-buildings .campus-building {
    @apply my-2 w-full cursor-pointer rounded-md bg-common-white px-4 py-2 shadow-sm;
}
.campus-buildings .campus-building .row {
    @apply flex items-center justify-between;
}
.campus-buildings .campus-building .row .description {
    @apply flex items-center gap-2;
}
.campus-buildings .campus-building .row i.icon-building {
    @apply text-lg text-interface-600;
}
.campus-buildings .campus-building .row .title {
    @apply mb-1 text-interface-950;
}
.campus-buildings .campus-building .row .size,
.campus-buildings .campus-building .row i.icon-next {
    @apply text-xs text-interface-600;
}

.campus-buildings .campus-building .row .size {
    @apply text-secondary-950;
}
/* building */
.organization-manager .wrapper {
    @apply rounded bg-common-white px-4 shadow-sm;
}
.organization-manager .map {
    @apply relative mt-6 h-[238px] w-full;
}
.organization-manager .wrapper .item {
    @apply grid grid-cols-3 gap-4 border-b border-interface-200 py-3 last:border-b-0;
}
.demand-list {
    @apply mb-4 rounded-md border border-interface-200 px-1;
}
.demand-list.organization-manager .wrapper .item {
    @apply grid grid-cols-3 border-interface-200 last:border-b;
}
.demand-list.organization-manager .wrapper:nth-last-of-type(1) .item {
    @apply grid grid-cols-3 border-interface-200 last:border-none;
}
.demand-list.organization-manager .wrapper .item label {
    @apply col-span-2;
}
.demand-list.organization-manager .wrapper .item p {
    @apply col-span-1 ml-auto text-right;
}
.organization-manager .wrapper p {
    @apply col-span-2 text-sm;
}
.time-slots-th {
    @apply grid grid-cols-10 gap-2;
}
.loading {
    @apply mx-auto mt-4 flex max-w-[90px];
}
.loading.data-loader {
    @apply flex h-full w-full items-center justify-center pb-4;
}
.progressbar-placeholder {
    @apply mr-1.5 h-4 w-[calc(100%-160px)] flex-shrink-0 animate-pulse bg-interface-300;
}
.usage-placeholder {
    @apply mr-1.5 h-4 w-full flex-shrink-0 animate-pulse bg-interface-300;
}
.divider {
    @apply block h-[1px] w-full bg-interface-200;
}
/* Tooltip */
.tooltip {
    @apply relative grid text-common-black;
}
.tooltip-content-top {
    transform: translateY(-100%);
}
.tooltip-content-bottom {
    transform: translateY(100%);
}
.tooltip-content {
    @apply invisible absolute -top-[10px] z-[99] w-max min-w-[auto] max-w-[290px] rounded-md bg-interface-700 px-2 py-2 text-sm text-common-white opacity-0 shadow-2xl;
}
.tooltip-content::before {
    content: '';
    @apply absolute aspect-square w-[10px] bg-interface-700;
}
.slider-container .tooltip-content::before {
    content: '';
    @apply absolute aspect-square w-[10px] bg-interface-300;
}
.tooltip-content-top::before {
    transform: translateX(-50%) rotate(45deg);
    @apply -bottom-[5px] left-2/4;
}
.tooltip-content-bottom::before {
    transform: translateX(-50%) rotate(-45deg);
    @apply -top-[5px] left-2/4;
}
.tooltip:hover .tooltip-content {
    transition: opacity 0.1s ease-in;
    @apply visible opacity-100;
}
.header-wrapper {
    @apply top-0 z-[9] -mx-6 flex items-center bg-common-white px-6 py-5 transition-all duration-200 lg:relative lg:flex-col lg:items-start;
}
.chart-header-selectbox {
    @apply mobile:!h-[1.75rem] mobile:flex-shrink-0;
}
.chart-header-selectbox + .icon-section {
    @apply mobile:top-[4px];
}
.chart-scrollable {
    @apply ipad:overflow-x-auto;
}
.chart-scrollable .chart-container {
    @apply ipad:w-[1000px];
}
/* ==========
    Header
============ */
.header-menu {
    @apply flex w-full items-center justify-end mobile:hidden;
}
.menu-toggle {
    @apply fixed right-4 top-2.5 z-[99] hidden cursor-pointer mobile:!block;
}
.menu-toggle .icon-nav-menu {
    @apply relative top-[2px] text-[2rem] text-common-white;
}
.company-logo {
    @apply ml-2 border-l border-primary-700 pl-4 mobile:hidden;
}
/* mobile header -> toggle & menu */
.header-right-side {
    @apply ml-auto mobile:flex-row-reverse mobile:!items-end mobile:!justify-start;
}
.header-menu.menu-wrapper {
    @apply mobile:!absolute mobile:left-0 mobile:top-[56px] mobile:!block mobile:bg-primary-900 mobile:p-2 mobile:opacity-0 mobile:shadow-sm mobile:transition-opacity mobile:duration-[0.05s] mobile:ease-in;
}
.hideHeader .rdt_Table .rdt_TableHeadRow {
    @apply hidden;
}
/* Disabled class */
.disabled {
    @apply pointer-events-none opacity-50;
}
.google-map-loader > div {
    @apply w-full;
}
.header-right-side {
    @apply ml-auto;
}
.header-menu {
    @apply flex w-full items-center justify-end mobile:hidden;
}
.menu-toggle {
    @apply fixed right-4 top-2.5 z-[99] hidden cursor-pointer mobile:!block;
}
.menu-toggle .icon-nav-menu {
    @apply relative top-[2px] text-[2rem] text-common-white;
}
.company-logo {
    @apply ml-2 w-[100px] border-l border-primary-700 pl-4 mobile:hidden;
}
/* mobile header -> toggle & menu */
.header-right-side {
    @apply mobile:flex-row-reverse mobile:!items-end mobile:!justify-start;
}
.header-menu.menu-wrapper {
    @apply mobile:!absolute mobile:left-0 mobile:top-[56px] mobile:!block mobile:bg-primary-900 mobile:p-2 mobile:opacity-0 mobile:shadow-sm mobile:transition-opacity mobile:duration-[0.05s] mobile:ease-in;
}
.menu-wrapper.header-menu {
    @apply mobile:!hidden;
}
.menu-wrapper.mobile-nav-active {
    opacity: 1; /* When menu-wrapper has the active class, make it visible */
    @apply mobile:!flex;
}
.menu-wrapper .company-logo {
    @apply mobile:mr-4 mobile:block;
}
/* right-sidearea */
.right-sidearea {
    transition: width 0.3s ease-in-out;
    @apply min-h-[calc(100vh-60px)] w-full border-interface-300 bg-common-white p-6 pt-0;
}
/* small sidebar */
.small-sidebar:not(:hover) .sidebar-selectbox > div > div > span + span {
    @apply hidden;
}
.small-sidebar:hover .sidebar-selectbox > div > div > span + span {
    @apply md:!hidden;
}
/* .small-sidebar:not(:hover) .sidebar-selectbox {
    @apply pointer-events-none;
} */
.small-sidebar:not(:hover) .sidebar-selectbox > div > div > span {
    @apply mr-0;
}
/* .small-sidebar:not(:hover) .separator-with-title .title-wrapper {
    @apply top-[-3px] pt-3;
} */
.small-sidebar:hover .separator-with-title .title-wrapper {
    @apply md:!top-[-3px] md:!pt-3;
}
.small-sidebar:not(:hover) .separator-with-title .title-wrapper span.title {
    @apply hidden;
}
/* .small-sidebar:hover .separator-with-title .title-wrapper span.title {
    @apply inline-flex;
} */
.small-sidebar:hover .separator-with-title .title-wrapper span.title {
    @apply md:!hidden;
}
.small-sidebar:not(:hover) .separator-with-title .lock-icon {
    @apply ml-1 text-lg;
}
.small-sidebar:hover .separator-with-title .lock-icon {
    @apply md:!ml-1 md:!text-lg;
}
.small-sidebar:not(:hover) .select-dropdown {
    @apply hidden;
}
.small-sidebar:hover .select-dropdown {
    @apply md:!hidden;
}
.animateMenuItems .menu-item,
.small-sidebar:hover .menu-item {
    animation: fadeIn 0.5s ease-in-out forwards;
}
.small-sidebar:hover .menu-item {
    @apply md:!animate-none;
}
.menu-item i.icon-stats {
    @apply text-[22px] font-extrabold;
}
.menu-item i.icon-sites {
    @apply text-[20px] font-semibold;
}
.menu-item i.icon-chatbot {
    @apply text-[18px];
}
.menu-item i.icon-control {
    @apply text-[18px] font-medium;
}
.menu-item i.icon-report,
.menu-item i.icon-create {
    @apply text-[23px];
}
/* sidebar lg (covers screen size upto 1199px) */
.nav-lg-screens,
.small-sidebar {
    @apply lg:z-[11] !important;
}
@keyframes fadeIn {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}
.separator-with-title,
.small-sidebar:hover .separator-with-title {
    @apply relative mb-4 w-full text-center;
}
.small-sidebar:not(:hover) .separator-with-title > span {
    @apply left-2 top-[-2px] pt-[13px];
}
.right-sidearea .guage-chart-wrapper {
    @apply relative h-[420px] p-0 3xl:h-[390px];
}
.right-sidearea .guage-chart-wrapper .guage-chart {
    @apply relative mx-auto flex h-[500px] w-full max-w-[560px] justify-center  pt-6 3xl:h-[450px] md:max-w-[400px];
}
.right-sidearea.sidebar-expanded .guage-chart-wrapper,
.small-sidebar:hover ~ div .right-sidearea .guage-chart-wrapper {
    @apply 3xl:h-[350px];
}
.right-sidearea.sidebar-expanded .guage-chart-wrapper .guage-chart,
.small-sidebar:hover ~ div .right-sidearea .guage-chart-wrapper .guage-chart {
    @apply h-[390px];
}
/* section bookmarked */
.icon-star.bookmarked {
    @apply text-primary-500;
}
.disabled .icon-star.bookmarked {
    @apply pointer-events-none text-interface-400;
}
.equipments-tooltip {
    @apply relative;
}
.equipments-tooltip .equipments-tooltip {
    @apply absolute right-[14px] top-[10px] z-[10px] hidden min-h-[50px] w-[200px] rounded-md bg-interface-100 p-2 text-xs text-interface-900;
}
.signal-table.base-table
    .rdt_TableBody
    .rdt_TableRow:nth-last-child(-n + 7)
    .equipments-tooltip
    .equipments-tooltip {
    @apply bottom-[10px] top-auto !important;
}
.equipments-tooltip:hover .equipments-tooltip {
    @apply z-50 block;
}
.equipments-tooltip .equipments-tooltip {
    @apply list-inside list-disc;
}
.bookmark-map .google-map-class {
    @apply relative;
}

/* Async Custom Select */
.custom__selectbox {
    @apply relative w-full text-sm;
}
.custom__selectbox .custom-react-select__control.custom-react-select__control {
    @apply mb-0 h-[28px] min-h-[28px] rounded-md border-interface-300 pt-0 text-interface-900 shadow-sm placeholder:text-interface-600;
}
.models
    .custom__selectbox
    .custom-react-select__control.custom-react-select__control {
    @apply mb-0 h-10 min-h-[28px] rounded-md border-interface-300 pt-0 text-interface-900 shadow-sm placeholder:text-interface-600;
}
.custom__selectbox.custom-react-select__md
    .custom-react-select__control.custom-react-select__control {
    @apply mb-0 h-10 rounded-md border-interface-300 text-interface-900 shadow-sm placeholder:text-interface-600;
}
.custom__selectbox.custom-react-select__md
    .custom-react-select__input-container {
    @apply mt-[6px];
}
.custom__selectbox.custom-react-select__md
    .custom-react-select__control.custom-react-select__control {
    @apply mb-0 h-10 rounded-md border-interface-300 text-interface-900 shadow-sm placeholder:text-interface-600;
}
.custom__selectbox.custom-react-select__md
    .custom-react-select__input-container {
    @apply mt-[6px];
}
.checkbox-container.large-container
    .custom__selectbox
    .custom-react-select__control.custom-react-select__control {
    @apply mb-0 h-10 rounded-md border-interface-300 pt-0 text-interface-900 shadow-sm placeholder:text-interface-600 hover:border-primary-300;
}
.custom__selectbox .custom-react-select__value-container {
    @apply top-[-3px];
}
.models .custom__selectbox .custom-react-select__value-container {
    @apply top-0;
}
.checkbox-container.large-container
    .custom__selectbox
    .custom-react-select__value-container {
    @apply top-0;
}
.custom__selectbox
    .custom-react-select__value-container.custom-react-select__value-container--is-multi.css-3w2yfm-ValueContainer {
    @apply top-[-1px] min-h-[26px] py-[0px];
}
.custom__selectbox.custom-react-select__md .custom-react-select__menu {
    @apply ml-[1px];
}
.custom__selectbox .custom-react-select__menu-list {
    @apply scrollable max-h-[250px];
}
.custom__selectbox.custom-react-select__md .custom-react-select__menu-list {
    @apply max-h-[232px];
}
.custom__selectbox .custom-react-select__placeholder {
    @apply overflow-hidden text-ellipsis whitespace-nowrap text-interface-600;
}
.custom__selectbox.custom-react-select__md .custom-react-select__placeholder {
    @apply mt-1;
}
/* selectbox disabled */
.custom__selectbox.disabled,
.custom__selectbox .custom-react-select--is-disabled.css-3iigni-container,
.custom-react-select__control.custom-react-select__control--is-disabled.css-16xfy0z-control,
.custom__selectbox
    .custom-react-select__control.custom-react-select__control--is-disabled
    .custom-react-select__placeholder {
    @apply pointer-events-none rounded-md border-interface-300 bg-interface-50 text-interface-400 hover:border-interface-300;
}
.models
    .custom-react-select__control.custom-react-select__control--is-disabled.css-16xfy0z-control {
    @apply pointer-events-none border-interface-400 bg-interface-200 text-interface-950 opacity-50 !important;
}
.models
    .custom__selectbox
    .custom-react-select__control.custom-react-select__control--is-disabled
    .custom-react-select__placeholder {
    @apply bg-interface-200 text-interface-950;
}
.models
    .custom__selectbox
    .custom-react-select__control.custom-react-select__control--is-disabled
    .custom-react-select__single-value--is-disabled {
    @apply text-interface-950;
}
.custom__selectbox.disabled .css-13cymwt-control {
    @apply bg-interface-50;
}
.custom__selectbox
    .custom-react-select__indicators
    .custom-react-select__indicator-separator {
    @apply hidden;
}
.custom__selectbox
    .custom-react-select__indicator.custom-react-select__dropdown-indicator {
    @apply pr-2 text-interface-400;
}
/* selectbox error */
.custom__selectbox.hasError,
.models .error .custom-react-select__control.custom-react-select__control {
    @apply rounded-md border border-danger-500;
}
.custom__selectbox.hasError
    .custom-react-select__control.custom-react-select__control {
    @apply border-none;
}

/* selectbox dropdown */
.select-all-option input:after {
    content: '';
    @apply absolute -top-[2px] left-0 h-[36px] w-full;
}
.checkbox-container.large-container .select-all-option input:after {
    content: '';
    @apply h-10;
}
.custom__selectbox .custom-react-select__option.custom-react-select__option,
.custom__selectbox
    .custom-react-select__option.custom-react-select__option.select-all-option {
    @apply hover:bg-primary-50 hover:text-interface-950 focus:bg-primary-50 active:bg-primary-50;
}
.custom__selectbox
    .custom-react-select__option.custom-react-select__option.select-all-option {
    @apply bg-common-white p-0;
}
.custom__selectbox
    .custom-react-select__option.custom-react-select__option.custom-react-select__option--is-selected.custom-react-select__option--is-selected,
.custom__selectbox .custom-react-select__option.selected-option {
    @apply bg-primary-300 text-common-white hover:bg-primary-300 hover:text-common-white;
}
.custom__selectbox
    .custom-react-select__control.custom-react-select__control
    .custom-react-select__multi-value {
    @apply flex h-[22px] items-center rounded bg-interface-200 px-[1px] py-0;
}
.custom__selectbox
    .custom-react-select__control.custom-react-select__control
    .custom-react-select__multi-value
    .custom-react-select__multi-value__remove {
    @apply hover:bg-transparent hover:text-danger-500;
}
.custom__selectbox
    .custom-react-select__indicator.custom-react-select__clear-indicator {
    @apply hidden;
}
.checkbox-container
    .custom__selectbox.multiSelect
    .custom-react-select__control {
    @apply scrollable max-h-[28px] overflow-y-scroll;
}
.checkbox-container.large-container
    .custom__selectbox.multiSelect
    .custom-react-select__control {
    @apply scrollable max-h-10 overflow-y-scroll;
}
.custom__selectbox.multiSelect .custom-react-select__indicators {
    @apply items-start !important;
}
.custom-react-select__indicator.custom-react-select__dropdown-indicator {
    @apply h-[8px] p-1;
}
.custom__selectbox.custom-react-select__md
    .custom-react-select__indicator.custom-react-select__dropdown-indicator {
    @apply flex items-center !important;
}
.models
    .custom-react-select__indicator.custom-react-select__dropdown-indicator {
    @apply h-auto;
}
.checkbox-container.large-container
    .custom__selectbox.multiSelect
    .custom-react-select__indicators {
    @apply mt-[5px] !important;
}
.checkbox-container .custom-react-select__value-container {
    @apply h-[26px];
}
.checkbox-container.large-container .custom-react-select__value-container {
    @apply h-[36px];
}
.checkbox-container
    .custom-react-select__value-container.custom-react-select__value-container--has-value {
    @apply h-auto;
}
/* Site map actions buttons */
.gmnoprint.gm-bundled-control > div:nth-child(1) > div {
    @apply hidden !important;
}
.gmnoprint.gm-bundled-control .gm-tilt.gm-control-active {
    @apply hidden !important;
}
/* Slider */
.slider-container {
    @apply relative flex w-full flex-col justify-center;
}
.slider-container .slider-value::before {
    content: '|';
    @apply absolute left-[4px] top-0 text-[10px] font-semibold text-interface-500;
}
.slider-container .slider-value-max::before {
    content: '|';
    @apply absolute right-[5px] top-0 text-[10px] font-semibold text-interface-500;
}
.react-datepicker__time-container {
    @apply w-[110px] !important;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    @apply w-[110px] !important;
}
/* Radiobox style */
.radioTabs label {
    @apply py-0 text-sm font-medium text-interface-600 hover:rounded-none hover:bg-transparent;
}
.radioTabs {
    @apply relative m-0 flex h-[38px] items-center justify-center overflow-hidden rounded-md border border-interface-300   hover:bg-interface-50;
}
.radioTabs input[type='radio'] {
    @apply hidden;
}
.radioTabs input[type='radio']:checked ~ span {
    @apply bg-primary-500 text-common-white;
}
.radioTabs .tabLabel {
    @apply w-full cursor-pointer overflow-hidden whitespace-nowrap px-[13px] py-[13px];
}
.radioTabs.disabled {
    @apply pointer-events-none opacity-50;
}
/* Radio Tab new style 2 */
.radioTabsNew {
    @apply flex h-[38px] w-full items-center justify-center p-1 font-medium text-interface-700 first:rounded-bl-md first:rounded-tl-md last:rounded-br-md last:rounded-tr-md;
}
.radioTabsNew input[type='radio'] {
    @apply hidden;
}
.radioTabsNew input[type='radio']:checked ~ span {
    @apply h-[30px] w-full rounded-md bg-interface-50 font-semibold;
}
.radioTabsNew .radioTabsLabel {
    @apply flex w-full justify-center;
}
.radioTabsNew .tabLabel {
    @apply flex w-full cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap;
}
.radioTabs.disabled {
    @apply pointer-events-none opacity-50;
}
/* Chat */
.chat-question-section .chat-sender {
    @apply absolute right-0 top-0 flex h-full w-full items-center justify-end pr-2;
}
.chat-question-section .chat-sender i {
    @apply text-base text-interface-400;
}
.chat-input.chat-input {
    @apply pr-[60px];
}
.google-map-class .gm-style-iw-t {
    @apply bottom-[48px] !important;
}
/* Ddropdown */
.dropdown-menu {
    @apply absolute right-0 mt-2.5 w-[200px] overflow-hidden rounded-md border border-interface-300 bg-common-white p-1 shadow-md focus:outline-none 2xl:w-[198px];
}
/* sidebar create dropdown */
.dropdown-menu.sidebar-dropdown-menu {
    @apply left-2 top-[26px] z-50 flex w-[217px] flex-col overflow-visible border-none bg-dark-blue p-0;
}
.dropdown-menu.chat-dropdown-menu {
    @apply bottom-[66px] z-50 flex w-[200px] flex-col overflow-visible border-none bg-primary-900 p-2 py-2 shadow-2xl;
}
.dropdown-menu.header-dropdown-menu {
    @apply left-[194px] top-1 z-50 flex w-[150px] flex-col overflow-visible shadow-xl;
}
.dropdown-menu button {
    @apply pl-2 pr-0 shadow-none;
}
.dropdown-menu button.parent-btn {
    @apply p-0;
}
.dropdown-menu .sub-items {
    @apply pl-1;
}
.dropdown-menu.dropdown-menu a,
.dropdown-menu.dropdown-menu .click-menu {
    @apply px-4 py-2 text-sm font-normal text-common-black hover:bg-primary-500 hover:text-common-white;
}
.dropdown-menu.dropdown-menu.link-dropdown-menu a,
.dropdown-menu.dropdown-menu.link-dropdown-menu .click-menu {
    @apply hover:bg-transparent;
}
.dropdown-menu.dropdown-menu a.active,
.dropdown-menu.dropdown-menu .click-menu.active {
    @apply bg-primary-500 font-normal;
}
.dropdown-menu a::after {
    @apply hidden;
}
/* Button as link */
.btn-link-danger {
    @apply !m-0 !h-[20px] !rounded-none border-0 border-b !border-danger-600 !p-0 !text-danger-600;
}
.btn-link-primary {
    @apply !m-0 !h-[20px] !rounded-none border-0 border-b !p-0 !text-primary-600;
}
.chat-animate-loader {
    width: 42px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side, #29aae1 90%, #0000) 0 /
        calc(100% / 3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 1s steps(4) infinite;
}
@keyframes l1 {
    to {
        clip-path: inset(0 -34% 0 0);
    }
}
/* eco-pilot table */
.eco-pilot-table > div {
    @apply max-h-[calc(100vh-400px)];
}

.analyze-chat {
    background-image: url('/eco-pilot-icon.svg');
    @apply h-[32px] w-[32px] bg-contain bg-no-repeat;
}
.control-sidebar-item .icon-down-arrow {
    @apply text-interface-600 !important;
}
.disabled-radio {
    @apply opacity-60;
}
.disabled-radio label {
    @apply opacity-100 !important;
}
/* Targeting Google Places Autocomplete suggestions */
.pac-item {
    @apply cursor-pointer !important;
}
.rdt_TableCell .good-performance {
    @apply bg-success-400;
}
.okay-performance {
    @apply bg-secondary-400;
}
.poor-performance {
    @apply bg-danger-400;
}
[data-column-id='r2'],
[data-column-id='cvRmse'] {
    @apply p-0 text-center !important;
}
.gm-ui-hover-effect {
    display: none !important;
}

/*
    Modal Sheet variant
*/
.modal-sheet-variant {
    @apply fixed right-0 top-0 h-full !items-start rounded-none  !p-0;
}
.modal-sheet-variant > div {
    @apply rounded-none;
}
.modal-sheet-variant .notification-modal-header > span {
    @apply left-[18px] top-[14px] z-40 w-[28px] text-interface-600;
}
.data-report-popup .notification-modal-header > span {
    @apply top-[23px];
}
.data-report-popup .notification-modal-header {
    @apply pl-[70px];
}
.menu-top .custom__selectbox .custom-react-select__menu {
    @apply bottom-[36px] top-auto ml-[1px];
}

@tailwind utilities;
