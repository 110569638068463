.inputLabel {
    @apply mb-1 inline-block text-sm font-medium text-interface-950;
}
.inputLabel.withAstrik {
    @apply flex items-start;
}
.astrik {
    @apply relative -top-1 ml-1 text-danger-500;
}
.inputModule {
    @apply relative;
}
.formGroup {
    @apply relative mb-4;
}
.darkSearchbox .formGroup {
    @apply mb-0;
}
.noSpace {
    margin-bottom: 0 !important;
}
.formControl,
.PhoneInputInput {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    @apply w-full h-10 px-3 text-sm border rounded-md border-interface-300 bg-common-white text-interface-950 placeholder:text-interface-600 focus:border-primary-300 focus:outline-none;
}
.formControl.md {
    @apply h-[48px];
}
.formControl.withoutBorder {
    @apply bg-transparent border-none shadow-none focus:shadow-none;
}
.formControl.hasError,
.isFormcontrolError .formControl.hasError {
    @apply border border-solid border-danger-600 !important;
}
.uploadError .formControl {
    @apply border-danger-600;
}
.formControl.dark {
    @apply border border-interface-500 bg-interface-600 text-common-white;
}
.formControl.light {
    @apply text-base border-interface-600 bg-interface-700 text-interface-400 placeholder:text-interface-300 focus:border-interface-300 focus:shadow-none focus:outline-none;
}
.form-control.dark i {
    @apply text-interface-200;
}
textarea.formControl {
    @apply max-h-[150px] min-h-[48px] w-full resize-none p-3;
}
.formControl.highlighted {
    @apply bg-interface-50 text-interface-900;
}
.formControl.disabled,
.formControl[disabled] {
    @apply opacity-50 pointer-events-none border-interface-400 bg-interface-200 text-interface-950;
}
/* Upload Field */
.formControl.uploadField {
    @apply flex items-center justify-between w-full cursor-pointer;
}
.formControl.uploadField .browse {
    @apply flex items-center rounded-xl bg-interface-100 px-2 py-0.5 text-interface-600;
}
.no-form-space {
    @apply mb-0;
}
.datetime {
    @apply relative inline-block w-full;
}
.datetime .form-control {
    @apply w-full;
}
.custom-selectbox select.sm {
    @apply h-[28px];
}
.dataPickerRange .startDate .formControl.sm,
.dataPickerRange .endDate .formControl.sm {
    @apply h-9;
}
.phoneInput {
    @apply relative;
}
.phoneInput .flagIcon {
    @apply absolute bottom-0 text-lg left-3 top-9;
}
.datetime .icon-calendar {
    @apply relative top-[-10px];
}
.dataPickerRange {
    @apply flex;
}
.dataPickerRange .startDate,
.dataPickerRange .endDate {
    @apply w-2/4;
}
.cardInterface .formControl {
    @apply h-auto p-0 border-0 shadow-none;
}
.dataPickerRange .startDate .formControl {
    @apply rounded-tr-none rounded-br-none;
}
.dataPickerRange .endDate .formControl {
    @apply ml-[-1px] rounded-bl-none rounded-tl-none;
}
.searchInput {
    @apply relative inline-block;
}
.searchInput .formGroup {
    @apply mb-0;
}
.searchInput input {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    @apply w-full h-10 px-3 text-sm border rounded-md border-interface-300 bg-common-white pl-9 text-interface-950 placeholder:text-interface-600 focus:border-primary-300 focus:outline-none;
}
.form-control .searchInput i {
    @apply text-sm;
}
.darkSearchbox .searchInput i {
    @apply text-interface-200;
}
.thumnailWrapper {
    @apply relative flex gap-4 mb-4;
}
.thumnail {
    @apply relative h-[38px] w-[38px] overflow-hidden rounded-md border border-interface-300 bg-common-white shadow-input-shadow;
}
.configuration-thumnail.configuration-thumnail .thumnail {
    @apply mb-0;
}
.thumnail span {
    @apply overflow-hidden rounded-lg;
}
.deleteImg {
    @apply absolute flex items-center justify-center w-5 h-5 rounded-full cursor-pointer right-2 top-2 bg-common-white text-common-black;
}
.filePreviewAside {
    @apply flex w-full gap-3;
}
.filePreviewAside .formGroup {
    @apply w-full;
}
.testing.testing .formGroup {
    @apply mb-0;
}
.thumbnailimage {
    @apply object-contain w-full h-full;
}
.customDurationPicker {
    @apply z-30 overflow-visible;
}
.customDurationPicker .icon-section {
    @apply absolute top-0 right-0 flex items-center justify-center h-full px-3;
}
.customDurationPicker input {
    letter-spacing: 3px;
    @apply relative left-0 top-0 h-[40px] w-[34px] min-w-0 text-center  outline-none;
}
.durationDropdown {
    @apply absolute left-[-1px]  top-[45px] h-[150px] w-[115px] rounded-md border border-interface-200 bg-common-white text-center shadow;
}
.dropdownItems li {
    @apply cursor-pointer py-1.5 text-sm;
}
.dropdownItems .selected,
.dropdownItems .active {
    @apply font-semibold bg-interface-200 text-common-black;
}
.bgPlaceholder {
    background-image: url(/storage/user-placeholder.png);
    @apply h-[38px] w-[38px] items-center justify-center overflow-hidden rounded-full bg-interface-100 bg-cover bg-center;
}
.bgPlaceholder .thumbnailimage {
    @apply object-cover;
}
.custom-selectbox {
    @apply relative;
}
.custom-selectbox option {
    @apply overflow-hidden;
}
/* switch btn */
.switch {
    @apply relative inline-block w-8 h-5;
}
.switch input {
    @apply w-0 h-0 opacity-0;
}
.slider {
    -webkit-transition: 0.4s;
    transition: 0.4s;
    @apply absolute top-0 bottom-0 left-0 right-0 cursor-pointer bg-interface-500;
}
.slider:before {
    content: '';
    -webkit-transition: 0.4s;
    transition: 0.4s;
    @apply absolute bottom-0.5 left-0.5 h-4 w-4 rounded-full bg-common-white;
}
input:checked + .slider {
    @apply bg-primary-500;
}
input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
}
.round {
    @apply rounded-[34px];
}
.cardInterface {
    @apply p-0;
}
.icon {
    @apply absolute text-lg right-2 top-7;
}
.uploadThumbs .deleteImg {
    @apply opacity-0;
}
.uploadThumbs:hover .deleteImg {
    @apply opacity-100;
}
.fieldText {
    @apply absolute right-[1px] top-[1px] flex h-[96%] min-w-[80px] h-[95%] items-center justify-center rounded-br-lg rounded-tr-lg border-l border-interface-300 bg-interface-50 px-4 text-xs text-interface-600;
}
