.select {
    @apply relative flex h-[40px] w-full cursor-pointer items-center justify-between bg-transparent py-2 pl-2 pr-4 text-left;
}
.label {
    @apply font-semibold;
}
.dropdownIcon {
    @apply text-[6px] text-primary-500;
}
.select .selectedOption {
    @apply flex items-center justify-center;
}
.select .selectedOption .label {
    @apply text-common-white;
}
.dropdown {
    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.21),
        0px 3px 5px 0px rgba(9, 30, 66, 0.1);
    @apply absolute z-10 w-full overflow-hidden rounded-md bg-dark-blue;
}
.dropdown .nestedList {
    @apply hidden;
}
.dropdown li.open .nestedList {
    @apply block;
}
.option {
    @apply flex cursor-pointer items-center justify-between border-b border-interface-700 px-2 py-2 pr-4 text-left text-sm text-interface-950 hover:bg-blue-800;
}
.dropdown li:last-child .option {
    @apply border-0;
}
.dropdown i {
    @apply text-common-white;
}
.option .optionLabel {
    @apply flex items-center justify-start text-common-white;
}
.option label,
.option span {
    @apply cursor-pointer;
}
.icon {
    @apply mr-2 flex h-5 w-5 items-center justify-center overflow-hidden rounded text-common-white;
}
.nestedOption {
    @apply cursor-pointer py-2 pl-6 pr-4 text-common-white last:border-b last:border-interface-700 hover:bg-blue-800;
}
.nestedOption.active {
    @apply font-semibold;
}
